import {
    Autocomplete,
    Chip,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormControl,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Specialities from "../../assets/data/specialities.json";
import api from "../../utils/Api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const DoctorModal = ({ open, onClose, doctorData, isEditDoctor }) => {
    const { t } = useTranslation();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };
    const [_id, setID] = useState("");
    const [doctorName, setDoctorName] = useState("");
    const [doctorEmail, setDoctorEmail] = useState("");
    const [doctorPhone, setDoctorPhone] = useState("");
    const [doctorExperience, setDoctorExperience] = useState("");
    const [doctorEducation, setDoctorEducation] = useState("");
    const [specialities, setSpecialities] = useState([]);
    const [doctorStatus, setDoctorStatus] = useState("ACTIVE");
    const [doctorType, setDoctorType] = useState("DOCTOR");
    const [registrationNumber, setRegistrationNumber] = useState("");


    useEffect(() => {
        if (Object.entries(doctorData).length > 0 && isEditDoctor) {
            setDoctorName(doctorData?.name);
            setDoctorEmail(doctorData?.email);
            setDoctorExperience(doctorData?.experience);
            setDoctorEducation(doctorData?.education);
            setDoctorPhone(doctorData?.phone);
            setDoctorType(doctorData?.role)
            setSpecialities([...doctorData?.specialities]);
            setDoctorStatus(doctorData?.doctorStatus);
            setID(doctorData?._id);
            setRegistrationNumber(doctorData?.registrationNumber)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePhone = (value) => {
        if (!isNaN(+value)) {
            setDoctorPhone(value);
        }
    }

    const handleSubmitDoctor = () => {
        try {
            let doctorPayload = {
                name: doctorName,
                experience: doctorExperience,
                education: doctorEducation,
                specialities,
                status: doctorStatus,
                type: doctorType,
                registrationNumber
            };
            if (!isEditDoctor) {
                doctorPayload = { ...doctorPayload, registrationNumber, email: doctorEmail, phone: doctorPhone }
            } else {
                doctorPayload = { ...doctorPayload, _id }
            }
            api.post(`/hospital/doctor`, doctorPayload)
                .then((res) => {
                    onClose();
                    toast.success(res && res?.message);
                })
                .catch((err) => {
                    toast.error("Error " + err);
                });

            // api.post(`/admin/doctor/update/${_id}`, doctorPayload)
            //     .then((res) => {
            //         onClose();
            //         toast.success("Doctor Updated Succesfully");
            //     })
            //     .catch((err) => {
            //         toast.error("Error " + err);
            //     });

        } catch (error) {
            toast.error(`Error ${error}`);
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={() => onClose('close')}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid
                        container
                        spacing={2}
                        display="flex"
                        justifyContent="left"
                        sx={{ mb: 3 }}>
                        <Grid item>
                            <Typography variant="h5" component="h5" align="center">
                                {isEditDoctor ? t('EDIT_DOCTOR') : t('CREATE_DOCTOR')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item sm={12} lg={6} md={6}>
                            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="doctor-type">
                                    User Type
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="doctor-type"
                                    id="doctor-type-deoctor"
                                    value={doctorType}
                                    label="Doctor Type"
                                    disabled={isEditDoctor}
                                    onChange={(e) => setDoctorType(e.target.value)}>
                                    <MenuItem value={"RECEPTION"}>Reception</MenuItem>
                                    <MenuItem value={"DOCTOR"}>Doctor</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                value={doctorName}
                                label="Name"
                                name="name"
                                onChange={(e) => setDoctorName(e.target.value)}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                label="Email"
                                name="email"
                                // disabled={isEditDoctor}
                                value={doctorEmail}
                                onChange={(e) => setDoctorEmail(e.target.value)}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                label="Phone"
                                name="phone"
                                // disabled={isEditDoctor}
                                onChange={(e) => handlePhone(e.target.value)}
                                value={doctorPhone}
                                inputProps={{ maxLength: 10 }}
                                size="small"
                            />
                        </Grid>
                        {doctorType !== 'RECEPTION' && (<><Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                label="Registration Number"
                                name="registrationNumber"
                                // disabled={isEditDoctor}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (!isNaN(+value)) {
                                        setRegistrationNumber(e.target.value)
                                    }
                                }}
                                value={registrationNumber}
                                size="small"
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                label="Experience"
                                size="small"
                                name="experience"
                                value={doctorExperience}
                                onChange={(e) => setDoctorExperience(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <TextField
                                fullWidth
                                type={"text"}
                                label="Education"
                                size="small"
                                name="education"
                                value={doctorEducation}
                                onChange={(e) => setDoctorEducation(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={12} lg={6} md={6}>
                            <Autocomplete
                                options={Specialities}
                                multiple
                                value={specialities}
                                onChange={(e, newValue) => {
                                    setSpecialities([...newValue]);
                                }}
                                getOptionLabel={(option) => `${option}`}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            variant="filled"
                                            label={`${option}`}
                                            {...getTagProps(option)}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="specialities"
                                        label="Specialization"
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        </>)}
                        < Grid item sm={12} lg={6} md={6}>
                            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label-doctor">
                                    Doctor Status
                                </InputLabel>
                                <Select
                                    size="small"
                                    labelId="demo-select-small-label-doctor"
                                    id="demo-select-small-deoctor"
                                    value={doctorStatus}
                                    label="Doctor Status"
                                    disabled={!isEditDoctor}
                                    onChange={(e) => setDoctorStatus(e.target.value)}>
                                    <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                                    <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            lg={12}
                            md={12}
                            display="flex"
                            justifyContent="right">
                            <Button variant="contained" onClick={handleSubmitDoctor}>
                                Submit
                            </Button>
                            <Button
                                sx={{ marginLeft: "5px" }}
                                variant="contained"
                                onClick={() => onClose('close')}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div >
    );
};

export default DoctorModal;
